<template>
  <a-modal @ok="handleOk" @cancel="onCancel" centered title="加签" :visible="visible" okText="加签并同意审批">
    <a-form class="opinion-form" ref="formRef" :rules="rules" layout="vertical" :model="forState">
      <div>
        <div>
          <span class="required-icon">*</span>
          加签审批人
          <span class="tip">(加签后，审批通过流程流转被加签人)</span>
        </div>
        <div class="sign">
          <div class="pro-item" v-for="item in signInfo" :key="item.name">
            <i class="iconfont icon-cha1 remove-icon" @click="deleteSta(item)" /> 
            <img :src="item.icon" alt class="pro-item-icon" />
  
            <div class="pro-name" v-if="item.dataName?.length < 4">{{ item.dataName }}</div>
            <a-popover v-else :getPopupContainer="e => e.parentNode" overlayClassName="lang-name-pop">
              <div class="lang-text-name">{{ item.dataName }}</div>
              <template #content>
                <div>{{ item.dataName }}</div>
              </template>
            </a-popover>
          </div>
          <div v-if="signInfo.length < 10" @click="addApproval">
            <img src="@/assets/images/business/chang-process.png" alt class="process-up" />
          </div>
        </div>
      </div>
      <a-form-item name="option" :labelCol="{ span: 4 }" label="加签备注" required>
        <a-textarea
          :autoSize="{ minRows: 6 }"
          v-model:value.trim="forState.option"
          placeholder="请输入加签备注"
          :maxlength="200"
          showCount
          class="option-textarea"
          @input="textChange"
        />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal :visible="modalShow" title="选择加签审批人" :maskClosable="true" width="655px" class="global-range-modal"
    @ok="Ok" @cancel="closeModal" centered>
    <GlobalSelector ref="globalSelector" :visible="modalShow" :selectorInfo="config" @getSearchInfo="getSearchInfo"
      :renderList="checkList" :selectedList="selectedList">
    </GlobalSelector>
  </a-modal>
</template>
<script>
import { reactive, toRefs, defineComponent, toRaw, createVNode } from 'vue'
import { cmsNotice } from '@/utils/utils'
import { getSearchData, getDepartmentOrStaff, getSelector } from '@/apis/businessManage'
import { formateListData } from '@/utils/selectorListFormate.js'
import GlobalSelector from '@/components/GlobalSelector/index.vue'
import { subApproval } from '@/apis/approval.js'
import { cmsMessage } from '@/utils/utils'
import { Modal, notification  } from 'ant-design-vue'
import { ExclamationOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    GlobalSelector
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    dataId: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      forState: {
        'option': ''
      },
      formRef: null,
      rules: {
        option: [
          {
            required: true,
            message: '请输入加签备注',
            trigger: 'blur'
          }
        ]
      },
      signInfo: [],
      checkList: [],
      selectedList: [],
      config: {
        limitCount: 10,
        isShowSelectedList: true, //[是否展示已选的列表]
        isShowBreadcrumb: true, //[是否显示面包屑]
        isSelectDept: true,
        placeholder: '请输入员工姓名',
        abelCheckAll: false,
        ableSelectDep: false,
        ableChangeData: false,
      },
      modalShow: false,
      globalSelector: null
    })

    const onCancel = () => {
      // state.formRef.resetFields()
      emit('update:visible', false)
      state.signInfo = []
      state.selectedList = []
      state.forState.option = ''
    }

    const handleOk = async () => {
      if(state.signInfo.length == 0) {
        cmsMessage('error', '请选择加签审批人！')
        return
      }else {
        state.formRef.validate().then(res => {
          let params = {
            'signStaffIds': [],
            'remark': state.forState.option,
            'dataId': props.dataId,
            ...toRaw(props.data)
          }
          state.signInfo.forEach(val => {
            params.signStaffIds.push(val.dataId)
          })
  
          params.state = 1
          subApproval(params).then(res=> {
            console.log('提交加签',res);
            if(res.success) {
              cmsMessage('success', '加签成功')
              emit('sbmit')
              state.forState.opinion = ''
              state.signInfo = []
              state.selectedList = []
            }else {
              if(res.code == 8011) {
                notification.destroy()
                Modal.confirm({
                  centered: true,
                  title: `本审批节点已通过，请勿重复操作`,
                  icon: createVNode(ExclamationOutlined),
                  okText: '确定',
                  cancelText: '返回审批列表页',
                  onOk() {
                    emit('onCancel', 'ok')
                  },
                  onCancel() {
                    emit('onCancel', 'cancel')
                  }
                })
              } else if(res.code === 8012) {
                //当前用户已拒绝
                notification.destroy()
                Modal.confirm({
                  centered: true,
                  title: `本审批节点已拒绝，请勿重复操作`,
                  icon: createVNode(ExclamationOutlined),
                  okText: '确定',
                  cancelText: '返回审批列表页',
                  onOk() {
                    emit('onCancel', 'ok')
                  },
                  onCancel() {
                    emit('onCancel', 'cancel')
                  }
                })
              }
            }
          })
        })
        .catch(err => {
          console.log(err);
          return err.errorFields[0].errors[0]
        })
      }
    }
    const addApproval = () => {
      console.log('打开弹窗');
      state.modalShow = true
      if(state.signInfo.length > 0 ) {
        state.selectedList = state.signInfo
      }
      getSearchInfo()
    }
    const deleteSta = (value) => {
      console.log('删除已选');
      const index = state.signInfo.findIndex(item => item.dataId == value.dataId)
      if(index != -1) {
        state.signInfo.splice(index, 1)
        state.selectedList.splice(index, 1)
      }
    }

    // 监听表情包输入
    const textChange = () => {
      const reg1 = /([\u00A9\u00AE\u203C\u2049\u2122\u2139\u2194-\u2199\u21A9-\u21AA\u231A-\u231B\u2328\u23CF\u23E9-\u23F3\u23F8-\u23FA\u24C2\u25AA-\u25AB\u25B6\u25C0\u25FB-\u25FE\u2600-\u2604\u260E\u2611\u2614-\u2615\u2618\u261D\u2620\u2622-\u2623\u2626\u262A\u262E-\u262F\u2638-\u263A\u2640\u2642\u2648-\u2653\u2660\u2663\u2665-\u2666\u2668\u267B\u267F\u2692-\u2697\u2699\u269B-\u269C\u26A0-\u26A1\u26AA-\u26AB\u26B0-\u26B1\u26BD-\u26BE\u26C4-\u26C5\u26C8\u26CE-\u26CF\u26D1\u26D3-\u26D4\u26E9-\u26EA\u26F0-\u26F5\u26F7-\u26FA\u26FD\u2702\u2705\u2708-\u270D\u270F\u2712\u2714\u2716\u271D\u2721\u2728\u2733-\u2734\u2744\u2747\u274C\u274E\u2753-\u2755\u2757\u2763-\u2764\u2795-\u2797\u27A1\u27B0\u27BF\u2934-\u2935\u2B05-\u2B07\u2B1B-\u2B1C\u2B50\u2B55\u3030\u303D\u3297\u3299]|\uD83C[\uDC04\uDCCF\uDD70-\uDD71\uDD7E-\uDD7F\uDD8E\uDD91-\uDD9A\uDDE6-\uDDFF\uDE01-\uDE02\uDE1A\uDE2F\uDE32-\uDE3A\uDE50-\uDE51\uDF00-\uDF21\uDF24-\uDF93\uDF96-\uDF97\uDF99-\uDF9B\uDF9E-\uDFF0\uDFF3-\uDFF5\uDFF7-\uDFFF])|(\uD83D[\uDC00-\uDCFD\uDCFF-\uDD3D\uDD49-\uDD4E\uDD50-\uDD67\uDD6F-\uDD70\uDD73-\uDD7A\uDD87\uDD8A-\uDD8D\uDD90\uDD95-\uDD96\uDDA4-\uDDA5\uDDA8\uDDB1-\uDDB2\uDDBC\uDDC2-\uDDC4\uDDD1-\uDDD3\uDDDC-\uDDDE\uDDE1\uDDE3\uDDE8\uDDEF\uDDF3\uDDFA-\uDE4F\uDE80-\uDEC5\uDECB-\uDED2\uDEE0-\uDEE5\uDEE9\uDEEB-\uDEEC\uDEF0\uDEF3-\uDEF6])|(\uD83E[\uDD10-\uDD1E\uDD20-\uDD27\uDD30\uDD33-\uDD3A\uDD3C-\uDD3E\uDD40-\uDD45\uDD47-\uDD4B\uDD50-\uDD5E\uDD80-\uDD91\uDDC0])/g
      state.forState.opinion = state.forState.opinion.replace(reg1, '')
    }
    const getSearchInfo = async data => {
      let params = {
        selectorType: 3,
        searchName: data,
        allData: true,
        addMultiFlag: true,
      }
      const res = await getSelector(params)
      let list = formateListData(res)
      console.log('走这来了ddddd222', list);
      const index = list.findIndex(item => item.dataId == props.data.staffId)
      if(index != -1) {
        list.splice(index, 1)
      }
      state.checkList = list
    }

    const Ok = () => {
      const arr = state.globalSelector.okEvent()
      console.log('Ok', arr);
      state.signInfo = arr
      state.modalShow = false
    }

    const closeModal = () => {
      console.log('关闭弹窗');
      state.modalShow = false
    }
    return {
      ...toRefs(state),
      handleOk,
      onCancel,
      textChange,
      addApproval,
      deleteSta,
      getSearchInfo,
      Ok,
      closeModal
    }
  },
  emits: ['sbmit', 'update:visible', 'onCancel']
})
</script>
<style lang="less" scoped>
.opinion-form {
  :deep(.opinion-textarea) {
    position: relative;
    width: 480px;
    &::after {
      position: absolute;
      right: 11px;
      bottom: 11px;
    }
  }
  .tip {
    color: rgba(0, 0, 0, 0.25);
  }
}
.process-up {
  width: 40px;
  height: 40px;
  margin-top: 12px;
  margin-bottom: 24px;
}

.lang-name-pop {
  .ant-popover-inner-content {
    max-width: 300px;
  }
}
.sign {
  display: flex;
  .pro-item {
    width: 68px;
    margin-top: 12px;
    margin-bottom: 24px;
    position: relative;
    .pro-item-icon {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 50%;
      object-fit: cover;
    }
    .remove-icon {
      position: absolute;
      top: 0;
      left: 32px;
      color: #c3161c;
      font-size: 12px;
      background: #ffffff;
      height: 12px;
      line-height: 12px;
      border-radius: 50%;
      cursor: pointer;
    }
    .lang-text-name {
      cursor: pointer;
      width: 50px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

</style>
